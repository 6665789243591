
.course-title {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    background-image: url('./media/bg1.png');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    iframe {
        width: 52vw;
        height: 30vw;
    }
    .course-header {
        font-size: 36px;
        font-weight: 700;
        width: 50vw;
        text-align: center;
        padding-bottom: 40px;
        span {
            color: #1c7cdc;
        }
    }
    .course-subheader {
        font-size: 20px;
        font-weight: 500;
        width: 50vw;
        text-align: center;
        padding-bottom: 40px;
    }
}
/* mobile */
@media (max-width: 549px) {
    .course-title {
        padding-top: 60px;
        padding-bottom: 30px;
        iframe {
            width: 85vw;
            height: 52vw;
        }
        .course-header {
            font-size: 30px;
            width: 90vw;
            padding-bottom: 15px;
            span {
                color: #1c7cdc;
            }
        }
        .course-subheader {
            font-size: 20px;
            font-weight: 500;
            width: 90vw;
            text-align: center;
            padding-bottom: 40px;
        }
    }
}
.yt-link {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 50px;
    }
}
.yt-link:hover {
    cursor: pointer;
}
/* mobile */
@media (max-width: 549px) {
    .yt-link {
        height: 120px;
        img {
            width: 40px;
        }
    }
}
.cal-link {
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    cursor: pointer;
    Button {
        width: 600px;
        height: 65px;
        font-size: 20px;
        font-weight: 600;
    }
}
/* mobile */
@media (max-width: 549px) {
    .cal-link {
        height: 60px;
        Button {
            width: 75vw;
            height: 50px;
            font-size: 18px;
        }
    }
}