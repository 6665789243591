@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family: 'Poppins', sans-serif;

html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	display: block;
   font-family: $font-family;
}
span {
   color:#1c7cdc;
}

.home-page {
   display: block;
   margin: 0;
   padding: 0;
   height: max-content;
	width: 100%;
}

NavLink {
   cursor: pointer;
}
Button {
   cursor: pointer;
}

.nav-bar {
   height: 170px;
   background-color: black;
   display: flex;
   justify-content: center;
   align-items: center;
   img {
      width: 150px;
      height: 150px;
   }
}
/* mobile */
@media (max-width: 549px) {
	.nav-bar {
      height: 120px;
      img {
         width: 100px;
         height: 100px;
      }
   }
}

.header {
   background-color: #ffffff;
   height: 680px;
   display: flex;
   padding: 0vw 5vw 0vw 5vw;
   background-image: url('./media/bg1.png');
   background-size: cover; 
   background-position: center; 
   background-repeat: no-repeat; 
}
/* mobile */
@media (max-width: 549px) {
	.header {
      flex-direction: column;
      height: 860px;
      padding: 0vw 0vw 0vw 0vw;
   }
}
.header-left {
   position: relative;
   width: 36vw;
   display: flex;
   align-items: center;
   justify-content: center;
   img {
      width: 400px;
      border-radius: 10%;
   }
}
/* mobile */
@media (max-width: 549px) {
	.header-left {
      width: 100vw;
      height: 400px;
      padding: 30px 0px 0px 0px;
      img {
         width: 85vw;
         border-radius: 0%;
      }
   }
}
.header-right {
   position: relative;
   width: 54vw;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
/* mobile */
@media (max-width: 549px) {
	.header-right {
      width: 100vw;
      height: 460px;
      padding: 30px 0px 0px 0px;
   }
}
.header-text {
   width: 640px;
   .title {
      font-size: 36px;
      font-weight: 700;
   }
   .subtitle {
      font-size: 18px;
      Button {
         width: 600px;
         height: 65px;
         font-family: $font-family;
         font-size: 20px;
         font-weight: 600;
         cursor: pointer;
      }
   }
}
/* mobile */
@media (max-width: 549px) {
	.header-text {
      width: 92vw;
      padding: 0vw 5vw 0vw 5vw;
      .title {
         font-size: 30px;
         font-weight: 700;
         text-align: center;
      }
      .subtitle {
         width: fit-content;
         font-size: 16px;
         text-align: center;
         Button {
            width: 200px;
            height: 50px;
            font-size: 18px;
            justify-content: center;
         }
      }
   }
}

.overview-title {
   height: 180px;
   display: flex;
   align-items: center;
   justify-content: center;
   .overview-bg {
      width: 800px;
      height: 100px;
      background-color: #3C6997;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: 600;
   }
}
/* mobile */
@media (max-width: 549px) {
	.overview-title {
      height: 200px;
      padding: 80px 0px 0px 0px;
      .overview-bg {
         width: 80vw;
         height: 120px;
         font-size: 20px;
         font-weight: 500;
         padding: 0px 20px 0px 20px;
         text-align: center;
      }
   }
}

.overview {
   height: 600px;
   background-color: rgb(255, 255, 255);
   display: flex;
   justify-content: center;
   padding: 70px 0px 0px 0px;
   background-image: url('./media/bg2.png');
   background-size: cover; 
   background-position: center; 
   background-repeat: no-repeat; 
   .overview-steps {
      height: 500px;
      width: 80vw;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      .step-card {
         background-color: rgb(244, 244, 244);
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         padding: 0px 40px 0px 40px;
         .card-title {
            font-size: 27px;
            font-weight: 600;
            text-align: center;
         }
         .card-subtitle {
            font-size: 17px;
            text-align: center;
         }
         img {
            width: 150px;
            height: 150px;
         }
      }
   }
}
/* mobile */
@media (max-width: 549px) {
	.overview {
      height: 1160px;
      padding: 10px 0px 0px 0px;
      .overview-steps {
         width: 90vw;
         display: flex;
         flex-direction: column;
         .step-card {
            padding: 12px 20px 12px 20px;
            .card-title {
               font-size: 23px;
            }
            .card-subtitle {
               font-size: 14px;
            }
            img {
               width: 120px;
               height: 120px;
            }
         }
      }
   }
}

.footer {
   background-color: #ffffff;
   height: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
   .footer-block {
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      img {
         margin-right: 10px;
         width: 15px;
         height: 15px;
      }
   }
}
/* mobile */
@media (max-width: 549px) {
	.footer {
      height: 100px;
      .footer-block {
         height: 30px;
         top: 5px;
         font-size: 12px;
         img {
            margin-right: 10px;
            width: 12px;
            height: 12px;
         }
      }
   }
}

hr {
	height: .03rem;
   background-color: black;
   margin: 0;
}